import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default function createStore() {
	return new Vuex.Store({
		state: {
			host: '',
			// deviceType: 'pc',
			deviceType: 'mobile',
			showDebug: false,
			adsensConfig: {
				scriptUrl: '',
				ads: '',
				home_1: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-715186', 'data-ad-slot': '3157446768', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				home_2: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-715186', 'data-ad-slot': '1844365098', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				home_3: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-715186', 'data-ad-slot': '9531283423', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				home_4: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-715186', 'data-ad-slot': '3235336379', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },

				all: [
					{ class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-715186', 'data-ad-slot': '3191104343', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
					{ class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-715186', 'data-ad-slot': '8296091364', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				],
			},
			adPosConfig: {
				all_m: [1, 2],
			},
			firebaseConfig: {
				apiKey: 'AIzaSyB4UwgXrurDMUbwGZuQTIgglU4pejcOoNk',
				authDomain: 'thevergeghi-shop01.firebaseapp.com',
				projectId: 'thevergeghi-shop01',
				storageBucket: 'thevergeghi-shop01.appspot.com',
				messagingSenderId: '7686472466',
				appId: '1:7686472466:web:bc294ec76e2fa6e9e529cb',
				measurementId: 'G-CRPMG7252K',
			},
		},
		mutations: {
			setHost(state, host) {
				state.host = host
			},
			setTitle(state, title) {
				state.title = title
			},
			setDomain(state, domainConfig) {
				state.domainConfig = domainConfig
			},
			setDeviceType(state, deviceType) {
				state.deviceType = deviceType
			},
			setDebug(state, showDebug) {
				state.showDebug = showDebug
			},
		},
		getters: {
			showDebug: (state) => state.showDebug,
			deviceType: (state) => state.deviceType,
			host: (state) => state.host,
			adsensConfig: (state) => state.adsensConfig,
			adPosConfig: (state) => state.adPosConfig,
		},
	})
}
